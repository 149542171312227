import React from "react";
import phone1 from "../assets/Phone1.png";
import phone2 from "../assets/Phone2.png";

const descriptionData = [
  {
    id: 1,
    image: phone1,
    tittle: "Find your tribe",
    description:
      "Discover new communities everyday, get to participate in their discussions, get updated with announcement feature, check your feed for updates from all the different communities.",
    background:
      "linear-gradient(to right, rgb(71, 118, 230, 44%), rgb(142, 84, 233, 31%))",
  },
  {
    id: 2,
    image: phone2,
    tittle: "Unlimited AI generations",
    description:
      "Generate AI will help you express your thoughts with the power of AI, get ranked in your community accordingly and make money by minting and selling your images as NFTs.",
    background:
      "linear-gradient(to right, rgb(242, 112, 156, 32%), rgb(255, 148, 114, 63%))",
  },
];

function Features2() {
  return (
    <div className="flex flex-col xl:w-[90%] w-[95%] md:space-y-[50px] space-y-[30px] md:mt-[110px] mt-[60px]">
      {descriptionData.map((data) => (
        <div key={data.id}
          className="flex lg:flex-row flex-col flex-wrap justify-between items-center w-full mx-auto py-[20px] lg:px-20 px-10 bg-red-300 rounded-[40px]"
          style={{ background: data.background }}
        >
          <div className="lg:w-[38%] sm:w-[40%] w-[250px]">
            <img className="" src={data.image} alt="" />
          </div>
          <div className="flex flex-col lg:w-[50%] sm:text-right">
            <h1 className="my-font xl:mt-[40px] mt-[2px] xl:text-[52px] lg:text-[48px] md:text-[38px] sm:text-[32px] text-[28px] line-clamp-2">
              {data.tittle}
            </h1>
            <h1 className="card-font md:mt-6 mt-2 font-semibold lg:text-xl sm:text-lg text-md">
              {data.description}
            </h1>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Features2;
