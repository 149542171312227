import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import loader from "../assets/loader.svg";

function ContactForm({ onClick }) {
  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-100 bg-opacity-50">
      <div className="w-full overflow-hidden rounded-lg bg-white max-w-4xl h-[72vh] lg:w-[500px] md:w-[500px] lg:h-[620px] md:h-[620px] relative">
        <IoCloseCircleOutline
          className="absolute top-4 right-4 cursor-pointer rounded-full h-[30px] w-[30px]"
          onClick={onClick}
          aria-label="Close"
        />
        <div className="absolute bottom-2 left-0 w-full h-[50px] bg-white" />

        {/* Loader */}
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-40">
            <div className="loader">
              <img src={loader} className="w-[100px] h-[100px]" />
            </div>
          </div>
        )}

        <iframe
          src="https://tally.so/r/3jWgDa?transparentBackground=1"
          className="w-full h-full border-0"
          title="Contact form"
          allowFullScreen
          onLoad={handleIframeLoad}
        ></iframe>
      </div>
    </div>
  );
}

export default ContactForm;
