import React from "react";

const cardsData = [
  {
    id:1,
    tittle: "Use decentralized messaging",
    description:
      "Private wallet-to-wallet message through XMTP and frictionless wallet creation with arcana.",
    background:
      "linear-gradient(to right, rgb(255, 179, 71, 100%), rgb(255, 204, 51, 100%))",
  },
  {
    id:2,
    tittle: "Build, scale  & explore communities",
    description:
      "Host your community on ratofy, and scale it to the next level with decentralized infrastructure to help you monetize and the users connect with each other.",
    background:
      "linear-gradient(to right, rgb(242, 112, 156, 100%), rgb(255, 148, 144, 100%))",
  },
  {
    id:3,
    tittle: "Gen-AI images to help you express",
    description:
      "With stable diffusion AI model integrated, generate images from text and express your thoughts with it, or mint it as NFT.",
    background:
      "linear-gradient(to top, rgb(0, 210, 255, 100%), rgb(58, 126, 213, 100%))",
  },
];

function Features1() {
  return (
    <div className=" flex w-full lg:justify-around justify-center lg:gap-0 gap-[40px] lg:flex-nowrap flex-wrap lg:mt-[70px] md:mt-[100px] sm:mt-[60px] mt-[50px]">
      {cardsData.map((data) => (
        <div key={data.id}
          className="flex flex-col xl:w-[350px] lg:w-[300px] sm:w-[280px]  w-[280px] xl:h-[350px] lg:h-[320px] sm:h-[300px]  h-[310px] bg-red-300 rounded-[40px]"
          style={{ background: data.background }}
        >
          <h1 className="my-font mt-[35px] sm:px-5 px-3 md:text-4xl text-3xl text-center line-clamp-2">
            {data.tittle}
          </h1>
          <h1 className="card-font xl:mt-4 lg:mt-2 mt-2 lg:px-8 px-6 font-medium xl:text-[18px] md:text-[16px] text-[16px]">
            {data.description}
          </h1>
        </div>
      ))}
    </div>
  );
}

export default Features1;
